<template>
  <v-dialog v-model="show" width="600" persistent>
    <v-card>
      <v-card-text class="dialog_message">
        {{ title }}
      </v-card-text>
      <v-card-text>
        <cropper :src="image" @change="_change" />
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          text
          color="secondary"
          class="ma-2 white--text"
          @click="confirm"
          :loading="loading"
        >
          Confirm
        </v-btn>
        <v-btn
          color="error"
          text
          class="ma-2 white--text"
          @click="dismissAction"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'crop-image-dialog',
  components: {
    Cropper
  },
  props: {
    show: { type: Boolean },
    loading: { type: Boolean, default: false },
    image: { type: String },
    title: { type: String },
    onImageCropped: { type: Function },
    dismissAction: { type: Function }
  },
  data() {
    return {
      canvas: null
    };
  },
  computed: {},
  methods: {
    _change({ canvas }) {
      console.log(canvas);
      this.canvas = canvas;
    },
    confirm() {
      this.canvas.toBlob((blob) => {
        this.onImageCropped(blob);
      });
    }
  }
};
</script>

<style scoped>
.btn_red {
  border-radius: 8px;
  height: 40px !important;
  line-height: 46px;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  background: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
  background-size: 200% auto;
}
.btn_red:hover {
  background-position: right center;
}
.btn_red .v-btn__content {
  width: auto;
}

::v-deep .v-dialog {
  border-radius: 15px;
  background: #fff;
}
.v-dialog .v-card {
  padding-top: 20px;
}
.v-dialog .theme--light.v-sheet {
  background-color: #fff !important;
}
.dialog_message {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  max-width: 340px;
  margin: 0 auto;
}
.v-card__actions {
  padding: 10px 0 30px;
  justify-content: center;
}
::v-deep .v-dialog::-webkit-scrollbar {
  width: 0 !important;
}
</style>
